<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <v-btn @click="$refs.employeeDialog.showDialog()" color="white primary--text" small>
        <v-icon left small>mdi-account-circle-outline</v-icon>
        Select Users
      </v-btn>

      <v-sheet width="350">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              prepend-inner-icon="mdi-calendar"
              label="All Time"
              v-bind="attrs"
              v-model="date"
              hide-details
              width="100%"
              v-on="on"
              clearable
              readonly
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1950-01-01"
            range
          ></v-date-picker>
        </v-menu>
      </v-sheet>
    </div>

    <v-card v-if="users.length" outlined class="box-shadow mt-5">
      <v-simple-table>
        <thead>
          <tr>
            <th>User</th>
            <th class="text-center">Total Tickets Solved</th>
            <th class="text-center">Average Response Time</th>
            <th class="text-center">Good Ratings</th>
            <th class="text-center">Ok Ratings</th>
            <th class="text-center">Bad Ratings</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user">
            <td>
              <user-photo
                photoSize="thumb"
                :id="user"
                :size="30"
                class="mr-3"
                rounded
              />

              {{ $store.getters['users/user'](user).fullName }}
            </td>
            <td class="text-center">{{ countUserCompletedRequests(user) }}</td>
            <td class="text-center">{{ calculateResponseTime(user) }}</td>
            <td class="text-center">{{ countUserHappyRatings(user) }}</td>
            <td class="text-center">{{ countUserOkayRatings(user) }}</td>
            <td class="text-center">{{ countUserBadRatings(user) }}</td>
            <td width="15">
              <v-btn @click="removeUser(user)" icon x-small>
                <v-icon color="error" small>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <!-- FIND EMPLOYEE DIALOG -->
    <EmployeeDialog ref="employeeDialog" :exclude="users" @confirmed="selected" multiple />
  </div>
</template>

<script>
import moment from 'moment'
import db from '@/firebase/init'
import { orderBy } from 'lodash'
import pluralize from 'pluralize'

import EmployeeDialog from '../users/components/EmployeeDialog.vue'

export default {
  metaInfo: {
    title: 'Requests Statistics'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      id: null,
      users: [],
      menu: null,
      date: null,
      allRatings: [],
      threads: [],
      complete: [],
      filter: null,
      requested: [],
      allRequests: [],
      responseTime: 0,
      ticketsSolved: 0,
      happinessRatings: 0,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    requests: function () {
      let requests = this.allRequests

      if (this.date) {
        let startDate = moment(new Date(this.date[0]))
        let endDate = moment(new Date(this.date[1]))

        requests = requests.filter(request => {
          return moment(request.created.toDate()) >= startDate && moment(request.created.toDate()) <= endDate
        })
      }

      return requests
    },

    ratings: function () {
      var ratings = this.allRatings

      ratings = ratings.filter(rating => {
        return this.requests.find(r => r.id == rating.id)
      })

      return ratings
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    selected(ids) {
      this.users = this.users.concat(ids)
      this.$refs.employeeDialog.closeDialog()

      this.getUserAssignedRequests(ids)
    },

    // REMOVE USER
    removeUser(id) {
      this.users.splice(this.users.indexOf(id), 1)
    },

    // GET COMPLETED REQUESTS
    getUserAssignedRequests(ids) {
      if (ids.length) {
        ids.forEach(id => {
          if (!this.requested.find(r => r == id)) {
            db.collection('requests')
            .where('assignee', '==', id)
            .get()
            .then((snapshot) => {
              if (snapshot.size) {
                this.requested.push(id)
                snapshot.forEach(doc => {
                  let request = doc.data()
                  request.id = doc.id
                  request.ref = doc.ref
                  this.allRequests.push(request)
                  this.getRatings(request.id)
                  this.getMessages(request)
                })
              }
            })
            .catch(error => {
              console.log(error.message)
            })
          }
        })
      }
    },

    // GET MESSAGES
    getMessages(request) {
      if (!this.threads.find(t => t.request == request.id)) {
        request.ref
        .collection('messages')
        .get().then((snapshot) => {
          if (snapshot.size) {
            let messages = []
            snapshot.forEach(doc => {
              var message = doc.data()
              message.id = doc.id
              message.ref = doc.ref
              messages.push(message)
            })
  
            this.threads.push({
              request: request.id,
              messages
            })
          }
        })
        .catch(error => {
          console.log(error.message)
        })
      }
    },

    // GET RATINGS
    getRatings(id) {
      if (!this.ratings.find(r => r.id == id)) {
        db.collection('feedbacks')
        .doc(id).get()
        .then(doc => {
          if (doc.exists) {
            let rating = doc.data()
            rating.id = doc.id
            rating.ref = doc.ref
            this.allRatings.push(rating)
          }
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // COUNT COMPLETED REQUESTS
    countUserCompletedRequests(id) {
      var requests = this.requests.filter(r => r.assignee == id && ['completed', 'pending_review'].includes(r.status))
      return requests.length
    },
    
    // COUNT USER HAPPY RATINGS
    countUserHappyRatings(id) {
      var ratings = this.ratings.filter(r => r.assignee == id && r.rating == 'happy')
      return ratings.length
    },
    
    // COUNT USER OKAY RATINGS
    countUserOkayRatings(id) {
      var ratings = this.ratings.filter(r => r.assignee == id && r.rating == 'okay')
      return ratings.length
    },
    
    // COUNT USER BAD RATINGS
    countUserBadRatings(id) {
      var ratings = this.ratings.filter(r => r.assignee == id && r.rating == 'bad')
      return ratings.length
    },

    // CALCULATE AVERAGE RESPONSE TIME
    calculateResponseTime(user) {
      var pairs = []
      var average = 0
      let requests = this.requests.filter(u => u.assignee == user)
      var threads = this.threads.filter(t => {
        return requests.find(r => r.id == t.request)
      })

      if (threads.length) {
        threads.forEach(thread => {
          if (thread.messages.length) {
            var request = requests.find(r => r.id == thread.request)
            var messages = thread.messages
            messages = orderBy(messages, (message) => {
              message.created.seconds
            }, 'asc')

            messages = messages.filter(message => {
              return !message.note && message.type !== 'status_change'
            })

            let pair = {
              client: null,
              assignee: null
            }

            messages.forEach(message => {
              if (!pair.client && message.sender == request.user)
                pair.client = message
              else if (pair.client && !pair.assignee && message.sender == request.assignee)
                pair.assignee = message

              if (pair.client && pair.assignee) {
                pairs.push(pair)

                pair = {
                  client: null,
                  assignee: null
                }
              }
            })
          }
        })
      }

      var monthMinutes = 43800
      var dayMinutes = 1440
      var hourMinutes = 60
      var month = 0
      var day = 0
      var hour = 0
      var minute = 0
      var text = ''

      if (pairs.length) {
        pairs.forEach(pair => {
          let diff = moment(pair.assignee.created.toDate()).diff(pair.client.created.toDate(), 'minutes')
          average += diff
        })

        average /= pairs.length

        if (average >= monthMinutes) {
          month = Math.floor(average / monthMinutes)
          average -= (monthMinutes * month)
        }

        if (average >= dayMinutes) {
          day = Math.floor(average / dayMinutes)
          average -= (dayMinutes * day)
        }
        
        if (average >= hourMinutes) {
          hour = Math.floor(average / hourMinutes)
          average -= (hourMinutes * hour)
        }

        if (average) minute = average
      }

      if (month) text += `${month} ${pluralize('month', month)} `
      if (day) text += `${day} ${pluralize('day', day)} `
      if (hour) text += `${hour} ${pluralize('hour', hour)} `
      if (minute) text += `${minute} ${pluralize('minute', minute)} `

      return text
    }
  },

}
</script>